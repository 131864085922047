require("@rails/ujs").start()

import 'application.scss'

const pdfs = require.context('../pdfs', true)

function closeFlashMessages() {
    const messages = document.querySelectorAll('.flash-message');
    for (const message of messages) {
        const closer = message.querySelector('button');
        if (!closer) continue;
        closer.addEventListener('click', () => {
            message.classList.add('fade-out');
        });
    }

    setTimeout(() => {
        for (const message of messages) {
            message.classList.add('fade-out');
        }
    }, 10_000);

    /*
    const burger = document.querySelector('#burger');
    const nav = document.querySelector('header nav');
    if (burger && nav) {
        burger.addEventListener('click', () => {
            nav.classList.toggle('visible');
        });
    }
    */
}

function checkStickyFooter() {
    if (window.innerHeight > document.body.offsetHeight) {
        document.body.classList.add('sticky-footer');
    } else {
        document.body.classList.remove('sticky-footer');
    }
}

function setupLoginDialog() {
    const loginForm = document.getElementById('user-login-form');
    if (!loginForm) return;

    document.addEventListener('click', (event) => {
        if (!loginForm.classList.contains('visible')) return;
        if (event.target.closest('#user-login-form')) return;
        loginForm.classList.remove('visible');
    }, true);
}

document.addEventListener("DOMContentLoaded", function () {
    closeFlashMessages();
    checkStickyFooter();
    setupLoginDialog();
});

window.addEventListener("load", function () {
    checkStickyFooter();
});

window.addEventListener('resize', () => {
    checkStickyFooter();
});
